import { Menu, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import includes from 'lodash/includes';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

type HeaderProps = {
  title: string;
  filterButton?: boolean;
  icon?: string;
  iconSize?: number;
  children?: React.ReactNode;
  endIcon?: string;
  username?: string | null | undefined;
  email?: string | null | undefined;
  name?: string | null | undefined;
  is_authenticated?: boolean | null | undefined;
  login?: () => void;
  logout?: () => void;
  mobileMenu?: React.ReactNode;
  openMobileMenu?: () => void;
  openModal?: () => void;
  logo?: string;
  filterCount?: number;
  backgroundImage?: string;
  textColor?: string;
  backgroundColor?: string;
};

// Avatar bg colors
const bgColors = ['bg-indigo-100', 'bg-sky-100'];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const usernameToNickname = (username: string) => {
  if (includes(username, '.')) {
    return `${username.charAt(0).toUpperCase()}${username.indexOf('.') > 0 ? username.charAt(username.indexOf('.') + 1).toUpperCase() : ''
      }`;
  } else {
    const parts: string[] | undefined = username.length > 0 ? username.split(' ') : ['U', 'A'];
    const initials = (typeof parts[0] === 'string') ?
      typeof parts[1] === 'string'
        && parts.length > 1 ? parts[0].charAt(0) + parts[1].charAt(0) : parts[0].charAt(0) : 'UA';
    return initials.toUpperCase();
  }
};

export const Header = (props: HeaderProps) => {
  const nickname = props.name ? usernameToNickname(props.name) : 'UA';
  return (
    <div
      className={`sticky top-0 z-40 border-gray-100 border-b-2 ${props.backgroundColor ? props.backgroundColor : 'bg-white'
        }`}
      style={{ backgroundImage: `url(${props.backgroundImage})` }}
    >
      <div className="mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between py-3 md:justify-start md:space-x-10">
          {/* Komète logo */}
          <div className="flex justify-start lg:w-0 lg:flex-1">
            {props.mobileMenu ? (
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 md:hidden"
                onClick={props.openMobileMenu}
              >
                <span className="sr-only">Open menu</span>
                {props.mobileMenu}
              </button>
            ) : null}
            <Link href="/">
              <Image
                className={props.backgroundColor ? props.backgroundColor : ''}
                src={`/${props.icon ?? 'logo-komete.svg'}`} //Default to Komète logo
                alt="logo komète"
                width={props.iconSize ?? 100}
                height={props.iconSize ?? 100}
              />
            </Link>
            <div className="hidden self-center ml-6 xl:flex">
              <span
                className={`font-medium ${props.textColor ? props.textColor : 'text-indigo-800'}`}
              >
                {props.title}
              </span>
            </div>
            <div>
              {props.is_authenticated && !!props.filterButton ? (
                <button
                  onClick={props.openModal}
                  type="button"
                  className="relative mt-2 self-center ml-6 xl:flex cursor-pointer justify-center items-center w-10 h-10 rounded-full hover:bg-slate-100 hover:bg-opacity-25"
                >
                  {props.filterCount && props.filterCount > 0 ? (
                    <span className="absolute w-5 h-5 text-white text-xs bg-red-500 rounded-full -right-2 -top-1 leading flex-shrink-0 inline-flex items-center justify-center">
                      {props.filterCount}
                    </span>
                  ) : null}

                  <MagnifyingGlassIcon
                    className={`h-6 w-6 ${props.textColor ? props.textColor : ''}`}
                  />
                </button>
              ) : null}
            </div>
          </div>
          {props.is_authenticated ? (
            <>
              {props.children ? <div>{props.children}</div> : null}
              <div className="items-center justify-end md:flex md:flex-1">
                <div className="flex">
                  {props.logo ? (
                    <div className="mr-1">
                      <Image
                        src={`/${props.logo}`}
                        alt="logo"
                        width={props.iconSize ?? 50}
                        height={props.iconSize ?? 50}
                      />
                    </div>
                  ) : null}
                  <div className="flex items-center space-x-2 py-1 px-2 rounded-md">
                    <Menu as="div" className="relative">
                      <div>
                        <Menu.Button>
                          <span className="sr-only">Open user menu</span>
                          <div
                            className={
                              'inline-flex overflow-hidden relative cursor-pointer xl:cursor-auto justify-center items-center w-10 h-10 rounded-full' +
                              ` ${bgColors[Math.floor(Math.random() * bgColors.length)]}`
                            }
                          >
                            <span className="font-medium text-gray-600">{nickname}</span>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="xl:hidden absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            <span
                              className={
                                'block px-4 py-2 text-xs text-gray-700 w-full text-center border-b font-semibold'
                              }
                            >
                              {props.username ?? 'Unknown'}
                            </span>
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={props.logout}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 w-full text-center'
                                )}
                              >
                                Déconnexion
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <div className="hidden xl:flex xl:flex-col">
                      <div
                        className={`font-medium text-xs ${props.textColor ? props.textColor : 'text-indigo-900'
                          }`}
                      >
                        {props.username ?? 'Unknown'}
                      </div>
                      <div
                        onClick={props.logout}
                        className={`font-medium text-xs cursor-pointer ${props.textColor ? props.textColor : 'text-indigo-900'
                          }`}
                      >
                        Déconnexion
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="items-center justify-end md:flex md:flex-1 lg:w-0 cursor-pointer">
              <div
                className="flex items-center space-x-2 py-1 px-2 rounded-md"
                onClick={props.login}
              >
                <div
                  className={
                    'inline-flex overflow-hidden relative justify-center items-center w-10 h-10 rounded-full' +
                    ` ${bgColors[Math.floor(Math.random() * bgColors.length)]}`
                  }
                >
                  <Image src={`/icons/person.svg`} alt="" width={18} height={18}></Image>
                </div>
                <div
                  className={`font-medium ${props.textColor ? props.textColor : 'text-indigo-900'}`}
                >
                  Login
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
