import Image from 'next/image';

type HeadButtonProps = {
  selected: boolean;
  label?: string;
  labelClass?: string;
  className?: string;
  icon?: string;
  focusIcon?: string;
  title?: string;
};

export const HeadButton = (props: HeadButtonProps) => {
  return (
    <>
      <button
        title={props.title ?? '/'}
        className={`flex h-full ${
          props.className
            ? props.className
            : 'bg-orange-500 hover:bg-orange-600 text-white py-1 px-2 rounded' // Default class
        }`}
      >
        {!!props.icon && !!props.focusIcon ? (
          <Image
            className={
              props.label !== undefined ? 'lg:mr-2 place-self-center' : 'place-self-center'
            } // Margin if label is present
            src={props.selected ? props.focusIcon : props.icon} // Icon to render on selected or not
            alt={props.icon}
            width={14}
            height={14}
          ></Image>
        ) : null}
        {props.label !== undefined ? (
          <span className={'text-xs lg:text-sm hidden lg:flex'}>{props.label}</span>
        ) : null}{' '}
        {/* Label */}
      </button>
    </>
  );
};
